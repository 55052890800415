<template>
  <div class="feature-header">
    <h3>{{ displayName }}</h3>
    <div class="row">
      <div class="col-9 col-lg-8">
        <ScoreBar :value="score" :min="0" :max="1" />
      </div>
      <div class="col-3 col-lg-4 pl-0">
        <ScoreLabel :score="score" :reverse="reverse" />
      </div>
    </div>
  </div>
</template>
<script>
const ScoreBar = () => import("Components/ScoreBar");
const ScoreLabel = () => import("Components/ScoreLabel");
export default {
  props: {
    score: Number,
    name: String,
    reverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    displayName() {
        return this.name?.toUpperCase();
    }
  },
  components: {
    ScoreBar,
    ScoreLabel,
  },
};
</script>
<style scoped lang="scss">
h3 {
  font-size: 1.5rem;
  font-family: "Nunito Sans Black", "Nunito Sans", sans-serif;
  font-weight: 900;
  color: black;
}

::v-deep .score-label {
  position: relative;
  top: -4px;
}
</style>
